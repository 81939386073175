const fixtures = {
  prosBullets: ["No annual fee", "New cardholder bonus offer"],
  consBullets: ["Complicated rewards", "No Intro APR", "No revolving balances"],
  benefits: {
    type: "Container",
    version: "1.0.0",
    props: {
      children: [
        {
          type: "Container",
          version: "1.0.0",
          props: {
            children: {
              type: "Type",
              version: "1.0.0",
              props: {
                children: ["Foo benefit"],
              },
            },
          },
        },
      ],
    },
  },
  drawbacks: {
    type: "Container",
    version: "1.0.0",
    props: {
      children: [
        {
          type: "Container",
          version: "1.0.0",
          props: {
            children: {
              type: "Type",
              version: "1.0.0",
              props: {
                children: ["Foo drawback"],
              },
            },
          },
        },
      ],
    },
  },
  facts: ["annualFee", "rewardRate", "introOffer", "recommendedCreditScore"],
  placementDrivers: [
    {
      id: "annualFee",
      label: "Annual fee",
      value: "$0",
      tooltip: null,
      description: null,
    },
    {
      id: "rewardRate",
      label: "Rewards rate",
      value: "1x-7x",
      tooltip: {
        type: "Container",
        version: "1.0.0",
        props: {
          children: {
            type: "Type",
            version: "1.0.0",
            props: {
              children:
                "Earn points on every dollar spent with industry-leading multipliers: 7x on rideshare, 4x on Brex Travel, 3x on restaurants, 2x on software subscriptions and 1x on all other transactions. Earn 3x Brex Rewards points on all eligible Apple purchases through the link or your Brex dashboard.",
            },
          },
        },
      },
      description: "Points",
    },
    {
      id: "introOffer",
      label: "Intro offer",
      value: "40,000",
      tooltip: {
        type: "Container",
        version: "1.0.0",
        props: {
          children: {
            type: "Type",
            version: "1.0.0",
            props: {
              children:
                "Get 10,000 points when you spend your first $1,000 on your Brex card, another 10,000 when you spend $3,000 in 3 months on your card, and 20,000 more when you link payroll to your Brex account.",
            },
          },
        },
      },
      description: "Points",
    },
  ],
  imgAlt: "Brex ",
  imgSrc:
    "https://www.nerdwallet.com/cdn-cgi/image/width=1800,quality=85/cdn/images/marketplace/credit_cards/f2fe19e2-c2cc-419a-aab1-5a36021e5e83/832d5a29cd678b276db4c953e44ddad12c63c6a61b16580850f4f2690981901c.jpg",
  heading: "Foo Card",
  bottomLine: {
    type: "Container",
    version: "1.0.0",
    props: {
      children: [
        {
          type: "Container",
          version: "1.0.0",
          props: {
            children: {
              type: "Type",
              version: "1.0.0",
              props: {
                children: ["Foo bottom line"],
              },
            },
          },
        },
      ],
    },
  },
  starRating: 3.3,
  reviewUrl: "https://www.nerdwallet.com/reviews/credit-cards/foo-card",
  cta: {
    link: "https://www.nerdwallet.com/redirect/foo?name=Brex-Commercial-Card&clickHeader_category=Credit+Card&clickHeader_productId=65556&mvt=MTY1NTQ4ODY2Mzc0MDY4ODQwMDphMGE5ODg1ZWEwZWU0NjMyMTcxNDIwYzAxMDExMjFiNWM4YzU4N2FkYWRkZTI1OTItNTY5NC00MzM5LWMzZmUtYzc4NjQ0MWE3OTIwOmI5NzViMGQ2M2MyMjU3ODY2NzhmMTA4ODc3ZWVmYjAyZDMxMTk4MTdkOGQ1MDY3ZDIwNWMyMDJmMGNiZGZmYzY%3D",
    text: "Apply now",
    subtext: "on Foo's website",
    type: "APPLY_NOW",
  },
  termsAndFeesLink: null,
  recommendedCreditScoreRangeDetails: {
    creditScoreRange: "300-850",
    description: "Poor - Excellent",
  },
  marketingBullets: [
    "Get 10,000 points when you spend your first $1,000 on your Brex card, another 10,000 when you spend $3,000 in 3 months on your card, and 20,000 more when you link payroll to your Brex account.",
    "No personal guarantee needed – Brex does not ask for a personal credit check or security deposit during the application.",
  ],
  minFico: 300,
  maxFico: 850,
  marketplaceEntity: {
    id: "f2fe19e2-c2cc-419a-aab1-5a36021e5e83",
    product: {
      id: "900199ff-de23-4198-aa84-ab7ccfb02ecf",
    },
    institution: {
      id: "a1f0c0fe-3aa2-4b47-902e-c71532dc4c7d",
      name: "Brex",
    },
    productType: "credit_cards",
  },
}

export default fixtures
