import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import LikelihoodOfApproval from "@src/components/LikelihoodOfApproval"
import { List, Li, Type } from "@nerdwallet/currency"
import { slugify, formatRating } from "@src/lib/helpers"
import Tooltip from "@src/components/Tooltip"
import StructuredContentRenderer from "@src/components/StructuredContentRenderer"
import AnchorLinkTarget from "@src/components/AnchorLinkTarget"
import Button from "@src/components/Button"
import TrackedCTA from "@src/components/TrackedCTA"
import ReviewButton from "@src/components/ReviewButton"
import EmailButton from "@src/components/EmailButton"
import ItemRating from "@src/components/ItemRating"
import Lazy from "react-lazyload"
import ResponsiveDisplay from "@src/components/ResponsiveDisplay"

import { PRODUCT_TYPES } from "@nerdwallet/nw-api-sdk/marketplace"

import {
  COMPARE_CTA_TEXT,
  COMPARE_CTA_TYPE,
  COMPARE_CTA_URL,
  RATES_AND_FEES_CTA_TYPE,
  TOOLTIP_TEXT,
} from "@src/lib/constants"

import {
  CardHeading,
  CardImageWrapper,
  CardWrapper,
  CardContentWrapper,
  CtaWrapper,
  QuickFacts,
  CardProsCons,
} from "../cardSubComponents"

import styles from "./CreditCardProductCard.module.less"

const CreditCardProductCard = ({
  creditCard,
  showCompareButton,
  showEmailButton,
  disableBorder,
  withRightCta,
  anchorTargetCards,
  columnedOnMobileOnly,
}) => {
  const {
    prosBullets,
    consBullets,
    benefits,
    drawbacks,
    facts,
    placementDrivers,
    imgAlt,
    imgSrc,
    heading,
    bottomLine,
    starRating,
    reviewUrl,
    cta,
    termsAndFeesLink,
    recommendedCreditScoreRangeDetails,
    marketingBullets,
    minFico,
    maxFico,
    productLabel,
    marketplaceEntity,
  } = creditCard

  const popStarRating = true

  const recommendedCreditScoreQuickFact = {
    id: "recommendedCreditScore",
    label: (
      <>
        <span className={columnedOnMobileOnly && styles.likelihoodHideOnMobile}>
          Recommended{" "}
        </span>
        Credit Score
      </>
    ),
    value: columnedOnMobileOnly && (
      <div className={styles.likelihoodHideOnDesktop}>
        {`${minFico}-${maxFico} (${recommendedCreditScoreRangeDetails.description})`}
      </div>
    ),
    valueComponent: (
      <div
        className={classNames(styles.likelihoodOfApprovalContainer, {
          [styles.likelihoodHideOnMobile]: columnedOnMobileOnly,
        })}
      >
        <LikelihoodOfApproval
          minFico={minFico}
          maxFico={maxFico}
          scoreRangeNumeric={
            recommendedCreditScoreRangeDetails.creditScoreRange
          }
          fullWidth
          scoreRangeText={recommendedCreditScoreRangeDetails.description}
        />
      </div>
    ),
  }

  const filteredPlacementDrivers = placementDrivers
    .concat(recommendedCreditScoreQuickFact)
    .filter(
      driver => driver.value !== null && facts.some(fact => fact === driver.id)
    )

  const prosCons = (
    <CardProsCons
      pros={benefits || prosBullets}
      cons={drawbacks || consBullets}
      reviewUrl={reviewUrl}
    />
  )

  const detailsComponent = (
    <List>
      {marketingBullets.map(d => {
        return <Li key={d}>{d}</Li>
      })}
      {termsAndFeesLink && (
        <Li>
          <a
            className={styles.viewRatesAndFeesLink}
            href={termsAndFeesLink}
            data-cta-type={RATES_AND_FEES_CTA_TYPE}
          >
            View Rates & Fees
          </a>
        </Li>
      )}
    </List>
  )

  const whyWeLikeIt = bottomLine && (
    <StructuredContentRenderer>{bottomLine}</StructuredContentRenderer>
  )

  const CtaButton = ({ large }) => (
    <>
      {cta?.link && (
        <TrackedCTA
          id={marketplaceEntity.id}
          productType={marketplaceEntity.productType}
          product={marketplaceEntity.product}
          institution={marketplaceEntity.institution}
          monetizable={marketplaceEntity.monetizable}
          cta={cta}
          ctaType="CC Product Card CTA"
          href={cta.link}
          buttonClassName={classNames({
            [styles.largeCta]: large,
          })}
        />
      )}
      {termsAndFeesLink && (
        <div className={styles.ratesAndFeesLink}>
          <a href={termsAndFeesLink} data-cta-type={RATES_AND_FEES_CTA_TYPE}>
            Rates & Fees
          </a>
        </div>
      )}
    </>
  )

  CtaButton.propTypes = {
    large: PropTypes.bool,
  }

  CtaButton.defaultProps = {
    large: false,
  }

  const emailButton = (
    <>
      <ReviewButton reviewUrl={reviewUrl} hideOnMobile />
      <EmailButton url={reviewUrl} />
    </>
  )

  const secondaryButton = showCompareButton ? (
    <Button
      primary={false}
      className={classNames("clickable", styles.button)}
      data-cta-type={COMPARE_CTA_TYPE}
      data-credit-card={heading}
      href={COMPARE_CTA_URL}
      target="_blank"
    >
      {COMPARE_CTA_TEXT}
    </Button>
  ) : (
    <ReviewButton reviewUrl={reviewUrl} />
  )

  const regularCard = (
    <CardWrapper
      productLabel={productLabel}
      largeSpacing
      disableBorder={disableBorder}
      withRightCta={withRightCta}
    >
      <CardImageWrapper
        imgAlt={imgAlt}
        imgSrc={imgSrc}
        heading={heading}
        withGap
      />
      <CardHeading heading={heading}>
        {starRating && reviewUrl && (
          <ItemRating
            displayNumber
            horizontal
            href={reviewUrl}
            rating={starRating}
            showReadReview={showCompareButton}
          />
        )}
      </CardHeading>
      <CtaWrapper withRightCta={withRightCta}>
        {showEmailButton ? emailButton : secondaryButton}
        <CtaButton />
      </CtaWrapper>
      <QuickFacts
        facts={facts}
        placementDrivers={filteredPlacementDrivers}
        columnedOnMobileOnly={columnedOnMobileOnly}
      />
      <CardContentWrapper
        whyWeLikeIt={whyWeLikeIt}
        prosCons={prosCons}
        detailsComponent={detailsComponent}
        heading={heading}
      />
    </CardWrapper>
  )

  const cardPoppingStarRating = (
    <CardWrapper
      productLabel={productLabel}
      largeSpacing
      disableBorder={disableBorder}
      withRightCta={withRightCta}
    >
      <div className={styles.popStarRatingWrapper}>
        <div className={styles.cardHeadingWrapper}>
          <CardHeading heading={heading} />
        </div>
        <div className={styles.cardStarRatingWrapper}>
          {starRating && reviewUrl && (
            <>
              <div>
                <Type size="1" color="neutral-darker">
                  NerdWallet rating&nbsp;
                  <span className={styles.tooltipIcon}>
                    <Tooltip
                      content={TOOLTIP_TEXT[PRODUCT_TYPES.CREDIT_CARDS]}
                      title="How is this rating determined?"
                    />
                  </span>
                </Type>
              </div>
              <div className={styles.ratingRow}>
                <Type inline className={styles.star}>
                  ★
                </Type>
                <Type inline bold size="3">
                  {formatRating(starRating)}
                </Type>
                <Type inline size="1" color="neutral-darker">
                  /5
                </Type>
              </div>
              <div>
                <a href={reviewUrl} data-cta-type="Star Rating Review">
                  <Type inline size="1">
                    Read review
                  </Type>
                </a>
              </div>
            </>
          )}
        </div>
        <div className={styles.cardImageWrapper}>
          <Lazy offset={100}>
            <img
              alt={imgAlt || `${heading} Logo`}
              src={imgSrc}
              style={{ objectFit: "inherit" }}
              data-cy="product-card-image"
              width="100%"
            />
          </Lazy>
        </div>
        <div className={styles.cardCtaWrapper}>
          <CtaWrapper withRightCta={withRightCta}>
            <CtaButton large />
          </CtaWrapper>
        </div>
      </div>
      <QuickFacts
        facts={facts}
        placementDrivers={filteredPlacementDrivers}
        columnedOnMobileOnly={columnedOnMobileOnly}
      />
      <CardContentWrapper
        whyWeLikeIt={whyWeLikeIt}
        prosCons={prosCons}
        detailsComponent={detailsComponent}
        heading={heading}
      />
    </CardWrapper>
  )

  const card = (
    <>
      <ResponsiveDisplay
        dataCy="regular-card"
        mobile={!popStarRating}
        desktop
        tablet
      >
        {regularCard}
      </ResponsiveDisplay>
      <ResponsiveDisplay
        dataCy="card-popping-star-rating"
        mobile={popStarRating}
      >
        {cardPoppingStarRating}
      </ResponsiveDisplay>
    </>
  )

  return anchorTargetCards ? (
    <AnchorLinkTarget target={slugify(marketplaceEntity.displayName)}>
      {card}
    </AnchorLinkTarget>
  ) : (
    card
  )
}

CreditCardProductCard.propTypes = {
  disableBorder: PropTypes.bool,
  showEmailButton: PropTypes.bool,
  creditCard: PropTypes.shape({
    benefits: PropTypes.shape({}),
    drawbacks: PropTypes.shape({}),
    prosBullets: PropTypes.arrayOf(PropTypes.string).isRequired,
    consBullets: PropTypes.arrayOf(PropTypes.string).isRequired,
    facts: PropTypes.arrayOf(PropTypes.string).isRequired,
    placementDrivers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        tooltip: PropTypes.any,
        description: PropTypes.string,
      })
    ),
    imgAlt: PropTypes.string.isRequired,
    imgSrc: PropTypes.string.isRequired,
    heading: PropTypes.node.isRequired,
    starRating: PropTypes.string.isRequired,
    bottomLine: PropTypes.node.isRequired,
    productDetails: PropTypes.arrayOf(PropTypes.string).isRequired,
    reviewUrl: PropTypes.string,
    marketingBullets: PropTypes.arrayOf(PropTypes.string).isRequired,
    productLabel: PropTypes.string,
    cta: PropTypes.shape({
      link: PropTypes.string.isRequired,
      subtext: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      type: PropTypes.string,
    }).isRequired,
    termsAndFeesLink: PropTypes.string,
    recommendedCreditScoreRangeDetails: PropTypes.shape({
      description: PropTypes.string.isRequired,
      creditScoreRange: PropTypes.string.isRequired,
    }).isRequired,
    maxFico: PropTypes.number.isRequired,
    minFico: PropTypes.number.isRequired,
    marketplaceEntity: PropTypes.shape({
      id: PropTypes.string,
      displayName: PropTypes.string,
      productType: PropTypes.string,
      product: PropTypes.shape({
        id: PropTypes.string,
      }),
      institution: PropTypes.shape({
        id: PropTypes.string,
      }),
      monetizable: PropTypes.string,
    }),
  }).isRequired,
  showCompareButton: PropTypes.bool,
  withRightCta: PropTypes.bool,
  anchorTargetCards: PropTypes.bool,
  columnedOnMobileOnly: PropTypes.bool,
}

CreditCardProductCard.defaultProps = {
  showCompareButton: false,
  disableBorder: false,
  withRightCta: false,
  anchorTargetCards: false,
  showEmailButton: false,
  columnedOnMobileOnly: false,
}

export default CreditCardProductCard
