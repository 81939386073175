import React from "react"
import PropTypes from "prop-types"

import classNames from "classnames"

import styles from "./ResponsiveDisplay.module.less"

const ResponsiveDisplay = ({
  children,
  customBreakpoints,
  desktop,
  mobile,
  tablet,
  dataCy,
}) => {
  return (
    <div
      data-cy={dataCy}
      className={classNames(styles.wrapper, {
        [styles.mobile]: !customBreakpoints && mobile,
        [styles.desktop]: !customBreakpoints && desktop,
        [styles.customMobile]: customBreakpoints && mobile,
        [styles.customTablet]: customBreakpoints && tablet,
        [styles.customDesktop]: customBreakpoints && desktop,
      })}
    >
      {children}
    </div>
  )
}

ResponsiveDisplay.propTypes = {
  children: PropTypes.node.isRequired,
  customBreakpoints: PropTypes.bool,
  desktop: PropTypes.bool,
  mobile: PropTypes.bool,
  tablet: PropTypes.bool,
  dataCy: PropTypes.string,
}

ResponsiveDisplay.defaultProps = {
  customBreakpoints: false,
  desktop: false,
  mobile: false,
  tablet: false,
  dataCy: null,
}

export default ResponsiveDisplay
