import React from "react"
import styles from "./LoremIpsum.module.less"

const LoremIpsum = () => (
  <div className={styles.wrapper}>
    <span>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eleifend
      luctus nulla vitae ultrices. Praesent hendrerit non diam sed accumsan.
      Integer id condimentum magna. Quisque tortor turpis, facilisis nec mauris
      at, hendrerit auctor tellus. Fusce dictum volutpat urna ac lacinia. Donec
      molestie dignissim pellentesque. Cras tempus volutpat sem. Curabitur
      interdum lobortis gravida. Duis nec laoreet mauris, in ultricies elit.
      Aenean vel ligula dictum, dignissim diam et, gravida sapien. Aenean
      malesuada sem vitae hendrerit rutrum. Nunc leo nunc, pharetra elementum
      rutrum a, ullamcorper id lacus. Donec interdum enim vitae libero convallis
      aliquet. Donec in odio in ex blandit blandit. Donec mauris orci, aliquet
      in tellus nec, elementum rutrum lorem. Nullam ultrices eros odio, ac
      sollicitudin dui vestibulum id.
    </span>
  </div>
)

export default LoremIpsum
