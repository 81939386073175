import React from "react"
import PropTypes from "prop-types"

import classNames from "classnames"
import Button from "@src/components/Button"
import { EMAIL_CTA_TYPE } from "@src/lib/constants"

import styles from "./EmailButton.module.less"

const emailData = {
  subject:
    "Reminder: You were looking at this business credit card on NerdWallet!",
  body: "Here’s the card you were looking at!",
}

const EmailButton = ({ url, noSpacing }) => {
  return (
    <Button
      primary={false}
      className={classNames(
        "clickable",
        styles.emailButton,
        styles.hideOnDesktop,
        {
          [styles.noSpacing]: noSpacing,
        }
      )}
      data-cta-type={EMAIL_CTA_TYPE}
      target="_blank"
      href={`mailto:?subject=${emailData.subject}&body=${emailData.body}%0D%0A %0D%0A${url}`}
    >
      Send This Card
    </Button>
  )
}

EmailButton.propTypes = {
  url: PropTypes.string.isRequired,
  noSpacing: PropTypes.bool,
}

EmailButton.defaultProps = {
  noSpacing: false,
}

export default EmailButton
