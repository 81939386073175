import React from "react"
import Page, { Footer, Header, HeaderContent } from "@src/components/Page"
import Column from "@src/components/Columns/Column"
import Columns from "@src/components/Columns/Columns"
import LoremIpsum from "@src/components/LoremIpsum"
import CreditCardProductCard from "@src/components/ProductCard/CreditCardProductCard"
import CreditCardData from "@src/components/ProductCard/CreditCardProductCard/__fixtures__/CreditCardProductCard.fixtures.js"
import DefaultSeparator from "./_component_default_separator"
import { ApplyUrlProvider } from "@src/hooks/useApplyUrl"
import { INSURANCE_TOPICS } from "@src/lib/constants"

const CreditCardProductCardPage = () => {
  const title = "Credit Card Product Card test page"
  const breadcrumb = {
    page: {
      title,
    },
    slug: "SMALL_BUSINESS",
  }
  return (
    <ApplyUrlProvider
      location={{ pathname: "/credit-card-product-card" }}
      attributionTopic={INSURANCE_TOPICS[0]}
    >
      <Page
        title={title}
        header={<Header />}
        headerContent={<HeaderContent title={title} breadcrumb={breadcrumb} />}
        footer={<Footer />}
      >
        <Columns>
          <Column width={{ mobile: 12 }}>
            <LoremIpsum />
            <DefaultSeparator />
            <span>Left Cta</span>
            <CreditCardProductCard creditCard={CreditCardData} />
            <DefaultSeparator />
            <span>Right Cta</span>
            <CreditCardProductCard creditCard={CreditCardData} withRightCta />
            <DefaultSeparator />
            <LoremIpsum />
          </Column>
        </Columns>
      </Page>
    </ApplyUrlProvider>
  )
}

export default CreditCardProductCardPage
