import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import classNames from "classnames"
import { isFlagActiveOnUrl } from "@src/lib/helpers"

import styles from "./_component_default_separator.module.less"

const Separator = () => {
  const [showSeparator, setShowSeparator] = useState(false)

  useEffect(() => {
    setShowSeparator(isFlagActiveOnUrl("separator"))
  }, [])

  return (
    <div
      className={classNames(styles.separator, {
        [styles.showSeparator]: showSeparator,
      })}
    />
  )
}

Separator.propTypes = {
  children: PropTypes.node,
}

export default Separator
